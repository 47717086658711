import React from 'react';
import PropTypes from 'prop-types';

export const ImgView = ({
    className,
    isDummy,
    src,
    alt
}) => (
    <img className={ className } style={ isDummy ? { objectPosition: 'center', objectFit: 'scale-down' } : {} } src={ src } alt={ alt } />
);

ImgView.propTypes = {
    className: PropTypes.string.isRequired,
    isDummy: PropTypes.bool.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};