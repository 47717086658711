import React, { useState, useEffect } from 'react';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import PropTypes from 'prop-types';

import { NavigationView } from './view';

export const Navigation = ({ searchQuery, setSearchQuery }) => {
    const [isVisible, setVisibility] = useState({
        navigation: false,
        products: false,
        reasons: false,
        brands: false,
        search: (typeof window !== 'undefined')
            && (window.location.pathname.includes('wyszukiwarka'))
    });
    const [isExpanded, setIsExpanded] = useState({
        internal: false,
        external: false,
        technical: false,
        accesories: false,
        custom: false
    });

    // combined with isExpanded state determines if the subCategories from the current category are visible
    const toggleIsExpanded = (element) => {
        setIsExpanded((currentState) => ({
            ...currentState,
            [element]: !currentState[element]
        }));
    };

    const toggleVisibility = (element) => {
        if (element === 'navigation') {
            setVisibility((previousVisibility) => ({
                ...previousVisibility,
                products: false,
                reasons: false,
                brands: false,
                [element]: !previousVisibility[element]
            }));
        } else {
            setVisibility((previousVisibility) => ({
                ...previousVisibility,
                [element]: !previousVisibility[element]
            }));
        }
    };

    useEffect(() => {
        if (window.matchMedia('(min-width: 1280px)').matches) {
            setIsExpanded(() => ({
                internal: true,
                external: true,
                technical: true,
                accesories: true,
                custom: true
            }));
        }

        window.addEventListener('resize', () => {
            if (window.matchMedia('(min-width: 1280px)').matches) {
                setIsExpanded(() => ({
                    internal: true,
                    external: true,
                    technical: true,
                    accesories: true,
                    custom: true
                }));

                setVisibility({
                    navigation: false,
                    products: false,
                    reasons: false,
                    brands: false,
                    search: (typeof window !== 'undefined')
                    && (window.location.pathname.includes('wyszukiwarka'))
                });
            } else {
                setIsExpanded(() => ({
                    internal: false,
                    external: false,
                    technical: false,
                    accesories: false,
                    custom: false
                }));
            }
        });
    }, []);

    useEffect(() => {
        if (window.matchMedia('(max-width: 1279px)').matches) {
            setIsExpanded(() => ({
                internal: false,
                external: false,
                technical: false,
                accesories: false,
                custom: false
            }));

            if (isVisible.navigation) {
                disableBodyScroll();
            } else {
                enableBodyScroll();
            }
        }
    }, [isVisible.navigation]);

    return (
        <NavigationView
            isVisible={ isVisible }
            searchQuery={ searchQuery }
            toggleVisibility={ toggleVisibility }
            setSearchQuery={ setSearchQuery }
            isExpanded={ isExpanded }
            setIsExpanded={ setIsExpanded }
            toggleIsExpanded={ toggleIsExpanded }
        />
    );
};

Navigation.defaultProps = {
    searchQuery: '',
    setSearchQuery: () => {}
};

Navigation.propTypes = {
    searchQuery: PropTypes.string,
    setSearchQuery: PropTypes.func
};