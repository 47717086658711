/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import { Link, navigate } from 'gatsby';
// import { useNavigate } from '@reach/router';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import cross from 'assets/images/cross.svg';
import menu from 'assets/images/navigation/menu.svg';
import baranskiDrzwi from 'assets/images/baranski-drzwi.svg';
import arrow from 'assets/images/arrow--viridian-green.svg';
import interiorDoor from 'assets/images/navigation/interior-door.svg';
import exteriorDoor from 'assets/images/navigation/exterior-door.svg';
import technicalDoor from 'assets/images/navigation/technical-door.svg';
import doorHandle from 'assets/images/navigation/door-handle.svg';
import setSquare from 'assets/images/navigation/set-square.svg';
import hanger from 'assets/images/navigation/hanger.svg';
import chisel from 'assets/images/navigation/chisel.svg';
import buzzSaw from 'assets/images/navigation/buzz-saw.svg';
import parchment from 'assets/images/navigation/parchment.svg';
import baranskiPremium from 'assets/images/baranski-premium.svg';
import baranskiOptimo from 'assets/images/baranski-optimo.svg';
import baranskiSmart from 'assets/images/baranski-smart.svg';
import magnifyingGlass from 'assets/images/magnifying-glass.svg';
import { paths } from 'utilities/routes';

import ue from 'assets/images/footer/ue.png';
import './view.scss';
import { Favorites } from 'utilities/components/favorites/component';

export const NavigationView = ({
    isVisible,
    searchQuery,
    toggleVisibility,
    setSearchQuery,
    isExpanded,
    toggleIsExpanded
}) => {
    const searchInputRef = useRef();

    const [isCategorySelected, setIsCategorySelected] = useState(false);

    // this function sets element dataset to true or false for it to be expanded. Happens on element with classname "contents__button"
    // if it is expanded is determined in the css by selector [data-is-expanded="false"] + ul (so the styles are applied to the closest ul)
    const expandLinks = (element) => {
        if (element.target.dataset?.isExpanded === 'true') {
            // eslint-disable-next-line no-param-reassign
            element.target.dataset.isExpanded = 'false';
        } else {
            // eslint-disable-next-line no-param-reassign
            element.target.dataset.isExpanded = 'true';
        }
    };

    useEffect(() => {
        if (isVisible.search) {
            searchInputRef.current.focus();
        }
    }, [isVisible]);

    const blurOnEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            setTimeout(() => {
                // eslint-disable-next-line no-unused-expressions
                searchInputRef?.current?.blur();
            }, 50);
        }
    };

    return (
        <header
            className={ classnames('header', {
                'header--active': isVisible.navigation
            }) }
        >
            <ul className="header__toolbar">
                <li>
                    <Link className="toolbar__link" to="/inwestycje">
                        Inwestycje
                    </Link>
                </li>
                <li>
                    <Link className="toolbar__link" to={ paths.doPobrania }>
                        Do pobrania
                    </Link>
                </li>
                <li>
                    <Link className="toolbar__link" to="https://zamowienia.baranskidrzwi.pl/" target="_blank">
                        B2B
                    </Link>
                </li>
                <li>
                    <Link className="toolbar__link" to="/faq">
                        FAQ
                    </Link>
                </li>
                <li>
                    <Link className="toolbar__link" to="/reklamacje">
                        Reklamacje
                    </Link>
                </li>
                <li>
                    <Link className="toolbar__link" to="/kontakt">
                        Kontakt
                    </Link>
                </li>
            </ul>
            <nav>
                <ul className="header__navigation">
                    <li className="navigation__toggle">
                        <button
                            type="button"
                            onClick={ () => {
                                toggleVisibility('navigation');
                            } }
                        >
                            <Img className="navigation__icon" src={ isVisible.navigation ? cross : menu } alt="ikona" />
                        </button>
                    </li>
                    <li className="navigation__baranski">
                        <Link to="/">
                            <Img className="navigation__logo" src={ baranskiDrzwi } alt="barański drzwi" />
                        </Link>
                    </li>
                    <li
                        className={ classnames('navigation__container', {
                            'navigation__container--active': isVisible.navigation
                        }) }
                    >
                        <ul className="navigation__list">
                            <li className="navigation__item">
                                <button
                                    className="navigation__link"
                                    type="button"
                                    onClick={ () => {
                                        toggleVisibility('products');
                                    } }
                                    onTouchStart={ () => {
                                        setIsCategorySelected(true);
                                    } }
                                    onMouseEnter={ () => {
                                        setIsCategorySelected(true);
                                    } }
                                    onMouseLeave={ () => {
                                        setIsCategorySelected(false);
                                    } }
                                >
                                    Produkty
                                    <Img className="navigation__arrow" src={ arrow } alt="strzałka" />
                                </button>
                                <ul
                                    className={ classnames('navigation__products', {
                                        'navigation__subnavigation--active': isVisible.products
                                    }) }
                                >
                                    <li className="products__contents">
                                        <ul className="subnavigation__categories">
                                            <li className="categories__item">
                                                <button
                                                    className="categories__button"
                                                    type="button"
                                                    onClick={ () => {
                                                        toggleVisibility('products');
                                                    } }
                                                >
                                                    Powrót
                                                </button>
                                            </li>
                                            <li className="categories__item">
                                                <ul className="categories__item-links-container">
                                                    <li className="categories__item-link">
                                                        <Link
                                                            className="categories__link"
                                                            to="/produkty/drzwi-wewnetrzne"
                                                            onClick={ () => {
                                                                toggleVisibility('navigation');
                                                            } }
                                                        >
                                                            <Img
                                                                className="categories__icon"
                                                                src={ interiorDoor }
                                                                alt="drzwi wewnętrzne"
                                                            />
                                                            <p
                                                                className={
                                                                    isCategorySelected
                                                                        ? 'categories__title link-selected'
                                                                        : 'categories__title'
                                                                }
                                                            >
                                                                Drzwi wewnętrzne
                                                            </p>
                                                        </Link>

                                                        <div
                                                            className={
                                                                isExpanded.internal ? 'arrow-expanded' : 'arrow-expand'
                                                            }
                                                            onClick={ () => toggleIsExpanded('internal') }
                                                        />
                                                    </li>

                                                    <li className="separator-line" />

                                                    <li
                                                        className={
                                                            isCategorySelected
                                                                ? 'categories__contents contents-selected'
                                                                : 'categories__contents'
                                                        }
                                                    >
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.internal
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Rodzaje
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/azurowe"
                                                                        >
                                                                            drzwi ażurowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/bezprzylgowe"
                                                                        >
                                                                            drzwi bezprzylgowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/dwuskrzydlowe"
                                                                        >
                                                                            drzwi dwuskrzydłowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/jednoskrzydlowe"
                                                                        >
                                                                            drzwi jednoskrzydłowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/lakierowane"
                                                                        >
                                                                            drzwi lakierowane
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/okleinowane"
                                                                        >
                                                                            drzwi okleinowane
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/pelne"
                                                                        >
                                                                            drzwi pełne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/przesuwne"
                                                                        >
                                                                            drzwi przesuwne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/przeszklone"
                                                                        >
                                                                            drzwi przeszklone
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/rewersyjne"
                                                                        >
                                                                            drzwi rewersyjne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/szklane"
                                                                        >
                                                                            drzwi szklane
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/ukryte"
                                                                        >
                                                                            drzwi ukryte
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/z-lustrem"
                                                                        >
                                                                            drzwi z lustrem
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.internal
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Pomieszczenia
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/lazienkowe"
                                                                        >
                                                                            drzwi łazienkowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/pokojowe"
                                                                        >
                                                                            drzwi pokojowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/tarasowe"
                                                                        >
                                                                            drzwi tarasowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/do-garderoby"
                                                                        >
                                                                            drzwi do garderoby
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/do-kuchni"
                                                                        >
                                                                            drzwi do kuchni
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/do-pralni"
                                                                        >
                                                                            drzwi do pralni
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/do-przedpokoju"
                                                                        >
                                                                            drzwi do przedpokoju
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/do-salonu"
                                                                        >
                                                                            drzwi do salonu
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/do-spizarni"
                                                                        >
                                                                            drzwi do spiżarni
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/do-sypialni"
                                                                        >
                                                                            drzwi do sypialni
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/do-wiatrolapu"
                                                                        >
                                                                            drzwi do wiatrołapu
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.internal
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Kolory
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/antracytowe"
                                                                        >
                                                                            drzwi antracytowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/bezowe"
                                                                        >
                                                                            drzwi beżowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/biale"
                                                                        >
                                                                            drzwi białe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/brazowe"
                                                                        >
                                                                            drzwi brązowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/czarne"
                                                                        >
                                                                            drzwi czarne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/dab-bielony"
                                                                        >
                                                                            drzwi dąb bielony
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/dab-naturalny"
                                                                        >
                                                                            drzwi dąb naturalny
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/dab-sonoma"
                                                                        >
                                                                            drzwi dąb sonoma
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/grafitowe"
                                                                        >
                                                                            drzwi grafitowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/niebieskie"
                                                                        >
                                                                            drzwi niebieskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/orzechowe"
                                                                        >
                                                                            drzwi orzechowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/szare"
                                                                        >
                                                                            drzwi szare
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/zielone"
                                                                        >
                                                                            drzwi zielone
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/zloty-dab"
                                                                        >
                                                                            drzwi złoty dąb
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.internal
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Style
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/amerykanskie"
                                                                        >
                                                                            drzwi amerykańskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/angielskie"
                                                                        >
                                                                            drzwi angielskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/art-deco"
                                                                        >
                                                                            drzwi art deco
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/francuskie"
                                                                        >
                                                                            drzwi francuskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/glamour"
                                                                        >
                                                                            drzwi glamour
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/hampton"
                                                                        >
                                                                            drzwi hampton
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/industrialne"
                                                                        >
                                                                            drzwi industrialne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/klasyczne"
                                                                        >
                                                                            drzwi klasyczne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/loftowe"
                                                                        >
                                                                            drzwi loftowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/nowoczesne"
                                                                        >
                                                                            drzwi nowoczesne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/prowansalskie"
                                                                        >
                                                                            drzwi prowansalskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/retro"
                                                                        >
                                                                            drzwi retro
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/rustykalne"
                                                                        >
                                                                            drzwi rustykalne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/skandynawskie"
                                                                        >
                                                                            drzwi skandynawskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-wewnetrzne/vintage"
                                                                        >
                                                                            drzwi vitange
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="categories__item">
                                                <ul className="categories__item-links-container">
                                                    <li className="categories__item-link">
                                                        <Link
                                                            className="categories__link"
                                                            to="/produkty/drzwi-zewnetrzne"
                                                            onClick={ () => {
                                                                toggleVisibility('navigation');
                                                            } }
                                                        >
                                                            <Img
                                                                className="categories__icon"
                                                                src={ exteriorDoor }
                                                                alt="drzwi zewnętrzne"
                                                            />
                                                            <p className="categories__title">Drzwi zewnętrzne</p>
                                                        </Link>

                                                        <div
                                                            className={
                                                                isExpanded.external ? 'arrow-expanded' : 'arrow-expand'
                                                            }
                                                            onClick={ () => toggleIsExpanded('external') }
                                                        />
                                                    </li>
                                                    <li className="categories__contents">
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.external
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Drzwi do domu
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/do-domu"
                                                                        >
                                                                            drzwi do domu
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/bez-szyby"
                                                                        >
                                                                            drzwi bez szyby
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/cieple"
                                                                        >
                                                                            drzwi ciepłe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/drewniane"
                                                                        >
                                                                            drzwi drewniane
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/energooszczedne"
                                                                        >
                                                                            drzwi energooszczędne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/ocieplane"
                                                                        >
                                                                            drzwi ocieplane
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/pasywne"
                                                                        >
                                                                            drzwi pasywne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/pojedyncze"
                                                                        >
                                                                            drzwi pojedyncze
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/antywłamaniowe"
                                                                        >
                                                                            drzwi wejściowe antywłamaniowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/z-antaba"
                                                                        >
                                                                            drzwi z antabą
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/z-dostawka "
                                                                        >
                                                                            drzwi z dostawką
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/z-doswietleniem"
                                                                        >
                                                                            drzwi z doświetleniem
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/z-kolatka"
                                                                        >
                                                                            drzwi z kołatką
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/z-oscieznica"
                                                                        >
                                                                            drzwi z ościeżnicą
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/z-szyba"
                                                                        >
                                                                            drzwi z szybą
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.external
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Drzwi do mieszkania
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <Link
                                                                            className="contents-links__link"
                                                                            to="/produkty/drzwi-zewnetrzne/do-mieszkania"
                                                                        >
                                                                            drzwi do mieszkania
                                                                        </Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link
                                                                            className="contents-links__link"
                                                                            to="/produkty/drzwi-zewnetrzne/wewnatrzklatkowe"
                                                                        >
                                                                            drzwi wewnątrzklatkowe
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.external
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Kolory
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/antracytowe"
                                                                        >
                                                                            drzwi zewnętrzne antracytowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/biale"
                                                                        >
                                                                            drzwi zewnętrzne białe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/brazowe"
                                                                        >
                                                                            drzwi zewnętrzne brązowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/czarne"
                                                                        >
                                                                            drzwi zewnętrzne czarne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/czerwone"
                                                                        >
                                                                            drzwi zewnętrzne czerwone
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/dab-naturalny"
                                                                        >
                                                                            drzwi zewnętrzne dąb naturalny
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/niebieskie"
                                                                        >
                                                                            drzwi zewnętrzne niebieskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/szare"
                                                                        >
                                                                            drzwi zewnętrzne szare
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/winchester"
                                                                        >
                                                                            drzwi zewnętrzne winchester
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/zielone"
                                                                        >
                                                                            drzwi zewnętrzne zielone
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/zloty-dab"
                                                                        >
                                                                            drzwi zewnętrzne złoty dąb
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.external
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Style
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/amerykanskie"
                                                                        >
                                                                            drzwi zewnetrzne amerykańskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/angielskie"
                                                                        >
                                                                            drzwi zewnętrzne angielskie
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/klasyczne"
                                                                        >
                                                                            drzwi zewnętrzne klasyczne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/nowoczesne"
                                                                        >
                                                                            drzwi zewnętrzne nowoczesne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/retro"
                                                                        >
                                                                            drzwi zewnętrzne retro
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-zewnetrzne/rustykalne"
                                                                        >
                                                                            drzwi zewnętrzne rustykalne
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="categories__item">
                                                <ul className="categories__item-links-container">
                                                    <li className="categories__item-link">
                                                        <Link
                                                            className="categories__link"
                                                            to="/produkty/drzwi-techniczne"
                                                            onClick={ () => {
                                                                toggleVisibility('navigation');
                                                            } }
                                                        >
                                                            <Img
                                                                className="categories__icon"
                                                                src={ technicalDoor }
                                                                alt="drzwi techniczne"
                                                            />
                                                            <p className="categories__title">Drzwi techniczne</p>
                                                        </Link>

                                                        <div
                                                            className={
                                                                isExpanded.technical ? 'arrow-expanded' : 'arrow-expand'
                                                            }
                                                            onClick={ () => toggleIsExpanded('technical') }
                                                        />
                                                    </li>
                                                    <li className="categories__contents">
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.technical
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Rodzaje
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-techniczne/akustyczne"
                                                                        >
                                                                            drzwi akustyczne
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-techniczne/antywlamaniowe"
                                                                        >
                                                                            drzwi antywłamaniowe
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/drzwi-techniczne/przeciwpozarowe"
                                                                        >
                                                                            drzwi przeciwpożarowe
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="categories__item">
                                                <ul className="categories__item-links-container">
                                                    <li className="categories__item-link">
                                                        <Link
                                                            className="categories__link"
                                                            to="/produkty/klamki"
                                                            onClick={ () => {
                                                                toggleVisibility('navigation');
                                                            } }
                                                        >
                                                            <Img
                                                                className="categories__icon"
                                                                src={ doorHandle }
                                                                alt="klamka"
                                                            />
                                                            <p className="categories__title">Klamki do drzwi</p>
                                                        </Link>

                                                        <div
                                                            className={
                                                                isExpanded.accesories
                                                                    ? 'arrow-expanded'
                                                                    : 'arrow-expand'
                                                            }
                                                            onClick={ () => toggleIsExpanded('accesories') }
                                                        />
                                                    </li>
                                                    <li className="categories__contents">
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.accesories
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Klamki do drzwi
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/klamki/klamki-do-drzwi-wewnetrznych"
                                                                        >
                                                                            klamki do drzwi wewnętrznych
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/klamki/klamki-do-drzwi-zewnetrznych"
                                                                        >
                                                                            klamki do drzwi zewnętrznych
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/klamki/klamki-do-drzwi-technicznych"
                                                                        >
                                                                            klamki do drzwi technicznych
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/klamki/pochwyty-do-drzwi"
                                                                        >
                                                                            pochwyty do drzwi
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/produkty/klamki/czytniki-linii-papilarnych"
                                                                        >
                                                                            czytniki linii papilarnych
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="categories__item">
                                                <ul className="categories__item-links-container">
                                                    <li className="categories__item-link">
                                                        <Link
                                                            className="categories__link"
                                                            to={ paths.drzwiNaWymiar }
                                                            onClick={ () => {
                                                                toggleVisibility('navigation');
                                                            } }
                                                        >
                                                            <Img
                                                                className="categories__icon"
                                                                src={ setSquare }
                                                                alt="ekierka"
                                                            />
                                                            <p className="categories__title">Drzwi na wymiar</p>
                                                        </Link>

                                                        <div
                                                            className={
                                                                isExpanded.custom ? 'arrow-expanded' : 'arrow-expand'
                                                            }
                                                            onClick={ () => toggleIsExpanded('custom') }
                                                        />
                                                    </li>
                                                    <li className="categories__contents">
                                                        <ul className="contents">
                                                            <li
                                                                className={
                                                                    isExpanded.custom
                                                                        ? 'navigation__item categories-expanded'
                                                                        : 'navigation__item categories-hidden'
                                                                }
                                                            >
                                                                <h3
                                                                    className="contents__button"
                                                                    onClick={ (e) => {
                                                                        expandLinks(e);
                                                                    } }
                                                                    data-is-expanded="false"
                                                                >
                                                                    Twój projekt
                                                                </h3>
                                                                <ul className="contents-links">
                                                                    <li>
                                                                        <a
                                                                            className="contents-links__link"
                                                                            href="/drzwi-na-wymiar"
                                                                        >
                                                                            drzwi na wymiar
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="navigation__item">
                                <button
                                    className="navigation__link"
                                    type="button"
                                    onClick={ () => {
                                        toggleVisibility('reasons');
                                    } }
                                >
                                    Dlaczego Barański
                                    <Img className="navigation__arrow" src={ arrow } alt="strzałka" />
                                </button>
                                <ul
                                    className={ classnames('navigation__subnavigation', {
                                        'navigation__subnavigation--active': isVisible.reasons
                                    }) }
                                >
                                    <li className="subnavigation__item">
                                        <button
                                            className="subnavigation__button"
                                            type="button"
                                            onClick={ () => {
                                                toggleVisibility('reasons');
                                            } }
                                        >
                                            Powrót
                                        </button>
                                    </li>
                                    <li className="subnavigation__item">
                                        <Link
                                            className="subnavigation__link"
                                            to="/jakosc"
                                            onClick={ () => {
                                                toggleVisibility('navigation');
                                            } }
                                        >
                                            <Img className="subnavigation__icon" src={ hanger } alt="zawieszka" />
                                            <p className="subnavigation__title">Jakość Barańskiego</p>
                                        </Link>
                                    </li>
                                    <li className="subnavigation__item">
                                        <Link
                                            className="subnavigation__link"
                                            to="/historia"
                                            onClick={ () => {
                                                toggleVisibility('navigation');
                                            } }
                                        >
                                            <Img className="subnavigation__icon" src={ chisel } alt="dłuto" />
                                            <p className="subnavigation__title">Historia</p>
                                        </Link>
                                    </li>
                                    <li className="subnavigation__item">
                                        <Link
                                            className="subnavigation__link"
                                            to="/artykuly/technologie"
                                            onClick={ () => {
                                                toggleVisibility('navigation');
                                            } }
                                        >
                                            <Img className="subnavigation__icon" src={ buzzSaw } alt="pilarka tarczowa" />
                                            <p className="subnavigation__title">Technologie</p>
                                        </Link>
                                    </li>
                                    <li className="subnavigation__item">
                                        <Link
                                            className="subnavigation__link"
                                            to="/certyfikaty"
                                            onClick={ () => {
                                                toggleVisibility('navigation');
                                            } }
                                        >
                                            <Img className="subnavigation__icon" src={ parchment } alt="pergamin" />
                                            <p className="subnavigation__title">Certyfikaty</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="navigation__item">
                                <button
                                    className="navigation__link"
                                    type="button"
                                    onClick={ () => {
                                        toggleVisibility('brands');
                                    } }
                                >
                                    Nasze marki
                                    <Img className="navigation__arrow" src={ arrow } alt="strzałka" />
                                </button>
                                <ul
                                    className={ classnames('navigation__subnavigation', {
                                        'navigation__subnavigation--active': isVisible.brands
                                    }) }
                                >
                                    <li className="subnavigation__item">
                                        <button
                                            className="subnavigation__button"
                                            type="button"
                                            onClick={ () => {
                                                toggleVisibility('brands');
                                            } }
                                        >
                                            Powrót
                                        </button>
                                    </li>
                                    <li className="subnavigation__item subnavigation__item--wide">
                                        <Link
                                            className="subnavigation__link"
                                            to="/premium"
                                            onClick={ () => {
                                                toggleVisibility('navigation');
                                            } }
                                        >
                                            <Img
                                                className="subnavigation__logo"
                                                src={ baranskiPremium }
                                                alt="barański premium"
                                            />
                                        </Link>
                                    </li>
                                    <li className="subnavigation__item subnavigation__item--wide">
                                        <Link
                                            className="subnavigation__link"
                                            to="/optimo"
                                            onClick={ () => {
                                                toggleVisibility('navigation');
                                            } }
                                        >
                                            <Img
                                                className="subnavigation__logo"
                                                src={ baranskiOptimo }
                                                alt="barański optimo"
                                            />
                                        </Link>
                                    </li>
                                    <li className="subnavigation__item subnavigation__item--wide">
                                        <Link
                                            className="subnavigation__link"
                                            to="/smart"
                                            onClick={ () => {
                                                toggleVisibility('navigation');
                                            } }
                                        >
                                            <Img
                                                className="subnavigation__logo"
                                                src={ baranskiSmart }
                                                alt="barański smart"
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="navigation__item">
                                <Link
                                    className="navigation__link"
                                    to="/realizacje"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    Realizacje
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link
                                    className="navigation__link"
                                    to="/wszystko-o-drzwiach"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    Wszystko o drzwiach
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link
                                    className="navigation__link"
                                    to="/katalogi"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    Katalogi
                                </Link>
                            </li>
                            <li className="navigation__item">
                                <Link
                                    className="navigation__link ue_link"
                                    to="/artykul/rozwijamy-sie-dzieki-funduszom-unijnym-stawiamy-na-nowe-technologie-i-ekologie-36"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    Unia Europejska
                                </Link>
                            </li>
                        </ul>
                        <ul className="navigation__toolbar">
                            <li>
                                <Link
                                    className="toolbar__link"
                                    to="/inwestycje"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    Inwestycje
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="toolbar__link"
                                    to={ paths.doPobrania }
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    Do pobrania
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="toolbar__link"
                                    to="https://zamowienia.baranskidrzwi.pl/"
                                    target="_blank"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    B2B
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="toolbar__link"
                                    to="/faq"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="toolbar__link"
                                    to="/reklamacje"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    Reklamacje
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="toolbar__link"
                                    to="/kontakt"
                                    onClick={ () => {
                                        toggleVisibility('navigation');
                                    } }
                                >
                                    Kontakt
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="navigation__catalogs">
                        <Link className="navigation__button" to="/gdzie-kupic">
                            Gdzie kupić
                        </Link>
                    </li>
                    <Favorites />
                    <li>
                        <button
                            type="button"
                            onClick={ () => {
                                toggleVisibility('search');
                            } }
                        >
                            <Img className="navigation__icon" src={ magnifyingGlass } alt="lupa" />
                        </button>
                    </li>
                    <li>
                        <Link to="/artykul/rozwijamy-sie-dzieki-funduszom-unijnym-stawiamy-na-nowe-technologie-i-ekologie-36">
                            <Img className="ue_icon" src={ ue } alt="ue" />
                        </Link>
                    </li>
                    <li
                        className={ classnames('navigation__search', {
                            'navigation__search--active': isVisible.search
                        }) }
                    >
                        <form
                            className="search__form"
                            onSubmit={ (event) => {
                                event.preventDefault();
                                const phrase = event.target.phrase.value.trim();

                                if (typeof window !== 'undefined' && phrase) {
                                    navigate(`/wyszukiwarka?fraza=${phrase}`, { replace: true });
                                } else {
                                    toggleVisibility('search');
                                    navigate(-1);
                                }
                            } }
                        >
                            <button type="submit">
                                <Img className="search__icon" src={ magnifyingGlass } alt="lupa" />
                            </button>
                            <input
                                className="search__input"
                                name="phrase"
                                type="text"
                                placeholder="Szukaj produktów, kategorii itp."
                                ref={ searchInputRef }
                                defaultValue={ searchQuery }
                                inputMode="search"
                                onChange={ (event) => {
                                    if (event.target.value.trim()) {
                                        setSearchQuery(event.target.value);
                                    }
                                } }
                                onKeyDown={ blurOnEnterKeyPressed }
                            />
                        </form>
                        <button
                            type="button"
                            onClick={ () => {
                                toggleVisibility('search');
                            } }
                        >
                            <Img className="search__icon" src={ cross } alt="iks" />
                        </button>
                    </li>
                </ul>
            </nav>
        </header>
    );
};
NavigationView.propTypes = {
    isVisible: PropTypes.instanceOf(Object).isRequired,
    searchQuery: PropTypes.string.isRequired,
    toggleVisibility: PropTypes.func.isRequired,
    setSearchQuery: PropTypes.func.isRequired,
    isExpanded: PropTypes.instanceOf(Object).isRequired,
    toggleIsExpanded: PropTypes.func.isRequired
};
