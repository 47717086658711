import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import ogImage from 'assets/images/og-image.jpeg';

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
            }
        }
    }
`;

const SEO = ({
    title, description, image, alt, robotsDirective, canonicalLink, noindex
}) => {
    const { site } = useStaticQuery(query);

    const { defaultTitle, defaultDescription } = site.siteMetadata;

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image || `https://www.baranskidrzwi.pl${ogImage}`,
        alt: alt || 'Barański Drzwi',
        robotsDirective: robotsDirective || ''
    };

    return (
        <Helmet htmlAttributes={{ lang: 'pl-PL' }} title={ seo.title } titleTemplate="%s">
            <meta name="description" content={ seo.description } />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={ canonicalLink } />
            <meta property="og:locale" content="pl_PL" />
            {canonicalLink && <link rel="canonical" href={ canonicalLink } />}
            {seo.image && <meta property="og:image" content={ seo.image } />}
            {seo.alt && <meta property="og:image:alt" content={ seo.alt } />}
            {seo.title && <meta property="og:title" content={ seo.title } />}
            {seo.description && <meta property="og:description" content={ seo.description } />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={ seo.title } />}
            {seo.description && <meta name="twitter:description" content={ seo.description } />}
            {seo.robotsDirective && <meta name="robots" content={ seo.robotsDirective } />}
            {noindex && <meta name="robots" content="noindex" />}
        </Helmet>
    );
};

export default SEO;

SEO.propTypes = {
    noindex: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    alt: PropTypes.string,
    robotsDirective: PropTypes.string,
    canonicalLink: PropTypes.string
};

SEO.defaultProps = {
    noindex: false,
    title: null,
    description: null,
    image: null,
    alt: null,
    robotsDirective: null,
    canonicalLink: null
};
