import React, { useCallback } from 'react';
import { Link } from 'gatsby';
import { paths } from 'utilities/routes';

import { ButtonView } from '../../button/component/view';
import { Img } from 'utilities/components/img/component';

import baranskiDrzwi from 'assets/images/baranski-drzwi.svg';
import facebook from 'assets/images/footer/facebook.svg';
import ue from 'assets/images/footer/ue.png';
import privacyPolicy from 'assets/docs/polityka-prywatnosci.pdf';

import './view.scss';

export const FooterView = () => {
    const collapse = useCallback((event) => {
        event.target.parentElement.classList.toggle('navigation__item--active');
    }, []);

    return (
        <footer className="footer">
            <Img className="footer__logo" src={ baranskiDrzwi } alt="barański drzwi" />
            <div className="footer__body">
                <ul className="body__navigation">
                    <li className="navigation__item">
                        <button
                            className="navigation__button"
                            type="button"
                            onClick={ (event) => {
                                collapse(event);
                            } }
                        >
                            Produkty
                        </button>
                        <ul className="navigation__subnavigation">
                            <li>
                                <Link className="subnavigation__link" to={ paths.drzwiWewnetrzne }>
                                    drzwi wewnętrzne
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.drzwiZewnetrzne }>
                                    drzwi zewnętrzne
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.drzwiKlatkoweITechniczne }>
                                    drzwi techniczne
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.klamki }>
                                    klamki
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.drzwiNaWymiar }>
                                    drzwi na wymiar
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="navigation__item">
                        <button
                            className="navigation__button"
                            type="button"
                            onClick={ (event) => {
                                collapse(event);
                            } }
                        >
                            Firma
                        </button>
                        <ul className="navigation__subnavigation">
                            <li>
                                <Link className="subnavigation__link" to={ paths.premium }>
                                    Barański Premium
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.optimo }>
                                    Barański Optimo
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.smart }>
                                    Barański Smart
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.realizacje }>
                                    Realizacje
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.jakosc }>
                                    Jakość Barański Drzwi
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.historia }>
                                    Nasza historia
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.technologie }>
                                    Nasze technologie
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.certyfikaty }>
                                    Certyfikaty
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="navigation__item">
                        <button
                            className="navigation__button"
                            type="button"
                            onClick={ (event) => {
                                collapse(event);
                            } }
                        >
                            Na skróty
                        </button>
                        <ul className="navigation__subnavigation">
                            <li>
                                <Link className="subnavigation__link" to={ paths.gdzieKupic }>
                                    Gdzie kupić?
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.wszystkoODrzwiach }>
                                    Wszystko o drzwiach
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.doPobrania }>
                                    Do pobrania
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.inwestycje }>
                                    Dla inwestorów
                                </Link>
                            </li>
                            <li>
                                <a
                                    className="subnavigation__link"
                                    href="https://zamowienia.baranskidrzwi.pl"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    B2B
                                </a>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.reklamacje }>
                                    Reklamacje
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.faq }>
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to={ paths.kontakt }>
                                    Kontakt
                                </Link>
                            </li>
                            <li>
                                <a className="subnavigation__link" to={ privacyPolicy } target="_blank" rel="noreferrer">
                                    Polityka prywatności
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="navigation__item">
                        <button
                            className="navigation__button"
                            type="button"
                            onClick={ (event) => {
                                collapse(event);
                            } }
                        >
                            Kolory drzwi wewnętrznych
                        </button>
                        <ul className="navigation__subnavigation">
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/antracytowe">
                                    drzwi wewnętrzne antracytowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/bezowe">
                                    drzwi wewnętrzne beżowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/biale">
                                    drzwi wewnętrzne białe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/brazowe">
                                    drzwi brązowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/czarne">
                                    drzwi wewnętrzne czarne
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/dab-bielony">
                                    drzwi wewnętrzne dąb bielony
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/dab-naturalny">
                                    drzwi wewnętrzne dąb naturalny
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/dab-sanoma">
                                    drzwi wewnętrzne dąb sonoma
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/grafitowe">
                                    drzwi wewnętrzne grafitowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/niebieskie">
                                    drzwi wewnętrzne niebieskie
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/orzechowe">
                                    drzwi wewnętrzne orzechowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/szare">
                                    drzwi wewnętrzne szare
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/zielone">
                                    drzwi wewnętrzne zielone
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/zloty-dab">
                                    drzwi wewnętrzne złoty dąb
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="navigation__item">
                        <button
                            className="navigation__button"
                            type="button"
                            onClick={ (event) => {
                                collapse(event);
                            } }
                        >
                            Kolory drzwi zewnętrznych
                        </button>
                        <ul className="navigation__subnavigation">
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/antracytowe">
                                    drzwi zewnętrzne antracytowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/biale">
                                    drzwi zewnętrzne białe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/brazowe">
                                    drzwi zewnętrzne brązowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/czarne">
                                    drzwi zewnętrzne czarne
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/czerwone">
                                    drzwi zewnętrzne czerwone
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/niebieskie">
                                    drzwi zewnętrzne niebieskie
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/szare">
                                    drzwi zewnętrzne szare
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/winchester">
                                    drzwi zewnętrzne winchester
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/zielone">
                                    drzwi zewnętrzne zielone
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-zewnetrzne/zloty-dab">
                                    drzwi zewnętrzne złoty dąb
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="navigation__item">
                        <button
                            className="navigation__button"
                            type="button"
                            onClick={ (event) => {
                                collapse(event);
                            } }
                        >
                            Często wyszukiwane
                        </button>
                        <ul className="navigation__subnavigation">
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/bezprzylgowe">
                                    drzwi bezprzylgowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/klasyczne">
                                    drzwi klasyczne
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/loftowe">
                                    drzwi loftowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/nowoczesne">
                                    drzwi nowoczesne
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/przesuwne">
                                    drzwi przesuwne
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/retro">
                                    drzwi retro
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/szklane">
                                    drzwi szklane
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/ukryte">
                                    drzwi ukryte
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="subnavigation__link"
                                    to="/produkty/drzwi-zewnetrzne/drzwi-wewnatrzklatkowe"
                                >
                                    drzwi wewnątrzklatowe
                                </Link>
                            </li>
                            <li>
                                <Link className="subnavigation__link" to="/produkty/drzwi-wewnetrzne/azurowe">
                                    drzwi ażurowe
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="subnavigation__link"
                                    to="/produkty/klamki/klamki-do-drzwi-wewnętrznych"
                                >
                                    klamki do drzwi wewnętrznych
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="subnavigation__link"
                                    to="/produkty/klamki/klamki-do-drzwi-zewnętrznych"
                                >
                                    klamki do drzwi zewnętrznych
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="navigation__item">
                        <button
                            className="navigation__button"
                            type="button"
                            onClick={ (event) => {
                                collapse(event);
                            } }
                        >
                            O nas
                        </button>
                        <ul className="navigation__subnavigation">
                            <li>
                                <p className="navigation__description">
                                    Naszą pasją są drzwi. Jesteśmy przedsiębiorstwem rodzinnym z polskim kapitałem,
                                    działającym na rynku od ponad 30 lat. Zatrudniamy wysokiej klasy specjalistów i
                                    dysponujemy nowoczesnym parkiem maszynowym. Nasze produkty cechuje światowy poziom
                                    jakości.
                                </p>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div className="footer__container">
                    <div className="footer__container-body">
                        <div className="footer__container-about">
                            <p className="footer__headline">O nas</p>
                            <p className="footer__description">
                                Naszą pasją są drzwi. Jesteśmy przedsiębiorstwem rodzinnym z polskim kapitałem,
                                działającym na rynku od ponad 30 lat. Zatrudniamy wysokiej klasy specjalistów i
                                dysponujemy nowoczesnym parkiem maszynowym. Nasze produkty cechuje światowy poziom
                                jakości.
                            </p>
                            <div className="footer__social-media">
                                Znajdź nas na
                                <a className="footer__link" href="https://www.facebook.com/baranskidrzwi">
                                    <Img className="footer__icon" src={ facebook } alt="facebook" />
                                </a>
                            </div>
                        </div>
                        <ButtonView text="Zobacz katalogi" link={ paths.katalogi } />
                    </div>
                    <Link
                        to="/artykul/rozwijamy-sie-dzieki-funduszom-unijnym-stawiamy-na-nowe-technologie-i-ekologie-36"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Img className="ue_icon" src={ ue } alt="ue" />
                    </Link>
                </div>
            </div>
        </footer>
    );
};
