import './view.scss';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { paths } from 'utilities/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';

// import heartIcon from 'assets/images/product/heart.png';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectFavorites } from 'utilities/redux/favoritesSlice';

const COLOR_VIRIDIAN_GREEN = '#62917b';

export const Favorites = () => {
    const favoritesValues = useAppSelector(selectFavorites);

    const getNumberOfFavorites = () => Object.keys(favoritesValues).reduce((acc, e) => {
        // eslint-disable-next-line no-param-reassign
        acc += Object.keys(favoritesValues[e]).length;
        return acc;
    }, 0);

    return (
        <div className="favorites-wrapper">
            <Link
                href={ paths.ulubione }
                className={ classnames('favorites-btn') }
            >
                <FontAwesomeIcon className="favorites-icon" style={{ color: COLOR_VIRIDIAN_GREEN }} icon={ fasHeart } />
                <span className={ classnames('favorites-number') }>{ getNumberOfFavorites() }</span>
            </Link>
        </div>
    );
};