import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import dummy from '../../../../assets/images/dummy.svg';

import { ImgView } from './view';

export const Img = ({ className, src, alt }) => {
    const [rightSrc, setRightSrc] = useState(src);

    const checkImg = () => {
        const img = new Image();
        img.src = src;

        if (img.complete) {
            setRightSrc(src);
        } else {
            img.onload = () => {
                setRightSrc(src);
            };

            img.onerror = () => {
                setRightSrc(dummy);
            };
        }
    };

    useEffect(() => {
        checkImg();
    }, [className, src, alt]);

    return <ImgView className={ className } isDummy={ src !== rightSrc } src={ rightSrc } alt={ alt } />;
};

Img.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};

Img.defaultProps = {
    className: ''
};