import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './view.scss';

export const ButtonView = ({
    text,
    link,
    // isExternal - e.g. download pdf
    isExternal,
    ...props
}) => (
    isExternal
        ? (<a className="button" href={ link } { ...props }>{ text }</a>)
        : (<Link className="button" to={ link } { ...props }>{ text }</Link>)
);

ButtonView.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    isExternal: PropTypes.bool
};

ButtonView.defaultProps = {
    isExternal: false
};